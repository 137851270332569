import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import { LicenseManager } from "ag-grid-enterprise";
import { CircularProgress } from "@mui/material";
import UserContext from "../UserContext";
import { useContext } from "react";
import { set } from "react-hook-form";
import AvailabilityRulesDetailRenderer from "./AvailabilityRulesDetails";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-30_June_2022_[v2]_MTY1NjU0MzYwMDAwMA==05007f3f89f0719199ee98bb5bd2edd5"
);

const useStyles = createUseStyles({
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    marginBottom: "15px",
  },

  pageTitle: {
    padding: "0px 0px 0px 10px",
    fontSize: "32px",
    marginBottom: 0,
  },

  pageSubTitle: {
    padding: "0px 0px 10px 20px",
    fontSize: "28px",
  },

  maxDetailContainer: {
    height: "88vh !important",
  },

  descriptionContainer: {
    paddingLeft: "5px",
  },

  subHeaderContainer: {
    paddingLeft: "13px",
  },
  pageDescription: {
    color: "#B3B2B3",
  },

  buttons:{
    display:'flex',
    paddingRight: '35px'
  }
});

const AvailabilityRules = () => {
  const styles = useStyles();
  const gridRef = useRef();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [userToken, setUserToken] = useState();
  const [isReqLoading, setReqLoading] = useState(true);
  const [columnDefs, setColumnDefs] = useState([
    { field: "id", headerName: "ID", hide: true , editable:false},
    {
      field: "document_type",
      headerName: "Document Type",
      cellRenderer: "agGroupCellRenderer",
    },
    { field: "leg" },
    { field: "always" },
    { field: "availability" },
    { field: "active" },
  ]);

  const updateAvailability = (newValue) => {
    const body = {type: 'availability' , data: newValue.data}
    console.log(body)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body:JSON.stringify(body),
    };

    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/rules/availability`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then(gridRef.current.api.refreshCells({force:true}))
      .catch((error)=>{
        console.log("error", error);
      })
    
    
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      editable: true
    };
  }, []);

  const onGridReady = useCallback((params) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/rules/availability`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(Object.values(data));
        setReqLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const detailCellRenderer = useMemo(()=>{
    return AvailabilityRulesDetailRenderer;
  },[])

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    };
  }, []);

  useEffect(() => {
    // setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    console.log("loading state");
  console.log(isLoading.isLoading);
  }, [isLoading.isLoading]);

  

  if (isReqLoading) {
    <div className="invoices">
      <CircularProgress style={{ color: "gray" }} size={70} />
    </div>;
  }

  const onAddRow = () => {
    console.log("hello")
    gridRef.current.api.applyTransaction({ add: [{}]})
  }

  const onRemoveSelectedRow = () =>{
    var selectedRowData = gridRef.current.api.getSelectedRows()
    selectedRowData.forEach(element => {
        let rowToRemove = {'data':{'id':element.id}}
        console.log(rowToRemove)
        updateAvailability(rowToRemove) 
    });
    gridRef.current.api.applyTransaction({remove: selectedRowData}) // TODO IF ANY ERROR OCCURED NOT APPLY IT
  }

  return (
    <div>
      <div className={styles.subHeaderContainer}>
        <div className={styles.titleContainer}>
            <div>
              <p className={styles.pageTitle}>Availability Rules</p>
            </div>
            <div className={styles.buttons}>
              <IconButton onClick={onAddRow} sx={{color: '#83b81a'}} aria-label="Add new availability rule"> 
                <AddCircleOutlineIcon/>
              </IconButton>
              <IconButton onClick={onRemoveSelectedRow} sx={{color: '#83b81a'}} aria-label="Remove selected availability rule"> 
                <RemoveCircleOutlineIcon/>
              </IconButton>
            </div>
        </div>
        <div>
          <div className={styles.descriptionContainer}>
            {/* <p className={styles.pageDescription}>
              Orders included in this invoice
            </p> */}
          </div>
        </div>
      </div>

      <div style={containerStyle}>
      
        <div style={gridStyle} className="ag-theme-alpine">
          <div className={styles.maxDetailContainer}> 
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              masterDetail={true}
              detailCellRenderer = {detailCellRenderer}
              onGridReady={onGridReady}
              //enableRangeSelection={true}
              pagination={true}
              paginationPageSize={50}
              sideBar={sideBar}
              editType={'fullRow'}
              onRowValueChanged={updateAvailability}
              rowSelection={'multiple'}
              enableCellChangeFlash={true}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityRules;
