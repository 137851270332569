import React, { useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import UserContext from "../UserContext";
import { useContext } from "react";
import { RecordStatus } from "../Components/CustomizeChip";

const useStyles = createUseStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px",
  },

  pageTitle: {
    padding: "15px 0px 0px 10px",
    fontSize: "32px",
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 0.22,
  },

  headerButton: {
    padding: "0px 20px 0px 20px",
  },
  headerButtonLast: {
    paddingRight: "20px",
    paddingLeft: "10px",
  },
  maxDetailContainer: {
    height: "88vh !important",
    // width:"100vw !important"
  },

  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const Shipments = (props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [style, setStyle] = useState({
    height: "100%",
    width: "100%",
  });
  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });
  const gridRef = useRef();

  const [columnDefs, setColumnDefs] = useState([
    { field: "id", headerName: "Shipment Number", 
      filter: 'agSetColumnFilter',
      filterParams: {
          values: async params => {
                let jsonData = await getFilterData('shipments', 'id');
                params.success(jsonData)
            }
      }},
    { field: "status", cellRenderer: RecordStatus,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: async params => {
              let jsonData = await getFilterData('shipments', 'status');
              params.success(jsonData)
          }
      }},
  ]);

  const getFilterData = async (tableName, columnName) =>{
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );
    const body = {tableName: tableName, column: columnName}
    
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow"
    };
    let dataToFilter = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/filter`,
      requestOptions
    )
      
    let dataToFilterJson = await dataToFilter.json()
    console.log('data :',dataToFilterJson)
    return dataToFilterJson
  }

  /*const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
    }),
    []
  );*/

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter:true,
      flex: 1,
      filterParams: {
        suppressAndOrCondition: true,
      }
    };
  }, []);

 /*const onGridReady = (params) => {
    setGridApi(params.api);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/shipment/view/all`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(data);
      })
      .catch((error) => console.log("error", error));
  };*/

  const serverSideRendering = {
    getRows(params){
      // called by the grid when more rows are required
      //getRows: params => {
        
        console.log('Test of serverside rendering :',JSON.stringify(params.request, null, 1))

        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
        );

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(params.request),
          redirect: "follow"
        };
        
        fetch(
          `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/shipments/view/all`,
          requestOptions
        )
          .then((resp) => resp.json())
          .then((resp)=> {
            console.log('last',resp) 
            params.successCallback(resp.rows, resp.lastRow)
          })
          .catch((error) => {
            console.log("error", error);
            params.fail();
          })
    }
  }

  const serverSideDatasource = serverSideRendering;

  const deselect = useCallback((e) => {
    gridRef.current.api.deselectAll();
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    const id = selectedRows.length === 1 ? selectedRows[0].id : "";

    navigate(`/item/shipment/${id}`);
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    };
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.forEachNode(function (node) {
      node.setExpanded(node.id === "-1");
    });
  }, []);

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <LocalShippingOutlinedIcon
            fontSize="large"
            sx={{
              color: "#83b81a",
            }}
          />
          <p className={styles.pageTitle}>Shipments</p>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
            onClick={deselect}
          >
            Deselect
          </Button>
          {/* <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
          >
            button2
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
          >
            button3
          </Button> */}
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <div className="ag-theme-alpine" style={{ height: 500 }}>
          <div className={styles.maxDetailContainer}>
            <AgGridReact
              ref={gridRef}
              onCellClicked={cellClickedListener}
              onRowDoubleClicked={onSelectionChanged}
              //rowData={rowData} client side rendering
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowSelection="multiple"
              //onGridReady={onGridReady} client side rendering
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={50}
              cacheBlockSize={50}
              sideBar={sideBar}
              enableSorting={true}
              multiSortKey='ctrl'
              rowModelType='serverSide' //Need for SERVER SIDE REDERING 
              serverSideStoreType='partial'
              serverSideDatasource={serverSideDatasource}
              serverSideSortOnServer={true}
              serverSideFilterOnServer={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipments;
