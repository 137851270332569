import { ChakraProvider } from "@chakra-ui/react";

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const Login = () => {
  //   const redirectToMicrosoftLogin = () => {
  //     // location.href = `${process.env.REACT_APP_COGNITO_HOSTED_UI_1}`;
  //     window.onload = function () {
  //       location.href = `${process.env.REACT_APP_COGNITO_HOSTED_UI_1}`;
  //     };
  //   };
  return (
    <ChakraProvider>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Sign in to your account</Heading>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <Stack spacing={10}>
                <Button
                  bg={"#83b81a"}
                  color={"white"}
                  _hover={{
                    bg: "#83b81a",
                  }}
                  onClick={(event) =>
                    (window.location.href = `${process.env.REACT_APP_COGNITO_HOSTED_UI_2}`)
                  }
                >
                  Login
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </ChakraProvider>
  );
};

export default Login;
