import { Grid } from '@mui/material';
import { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import UserContext from "../UserContext";
import { useContext } from "react";
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import Xarrow from "react-xarrows";
import * as CountryFlagIcons from 'country-flag-icons/react/3x2'
import { hasFlag } from 'country-flag-icons'


const useStyles = createUseStyles({
  titleContainer: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },

  pageTitle: {
    padding: "0px 0px 0px 10px",
    fontSize: "32px",
    marginBottom: 0,
  },

  writtenElem: {
    fontSize: "20px"
  },

  allScheme: {
    paddingLeft: "0px", 
    paddingRight: "0px",
  },

  subHeaderContainer: {
    paddingLeft: "13px",
  },
});

const iconStyle = (props) => {
  return ({
    color: props === true ? "#83b81a" : "#9EA3AD",
    fontSize: "60"
  });
}

const arrowStyle = (props) => {
  if (props === true) {return "#83b81a"}
  return "#9EA3AD"
}

const arrowBackgroundStyle = (props) => {
  return ({
    background: props === true ? "#83b81a" : "#9EA3AD",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderTopLeftRadius: '20px', 
    borderBottomLeftRadius: '20px', 
    borderTopRightRadius: '20px', 
    borderBottomRightRadius: '20px' 
  });
}

const Country = (props) => {
  if (props.unicode === "-" || hasFlag(props.unicode) === false) {
    return (<div>-flag-</div>);
  }
  const CountryComp = CountryFlagIcons[props.unicode];
  console.log(props.unicode)
  console.log(CountryComp);
  return (
    <CountryComp style={{border: "1px solid black"}}></CountryComp>
  );
}

function mergeShipments(shipments, typ){
  const start = "Shipment " + typ + "\n";
  const end = shipments.join("\n");
  const result = start.concat("", end);
  return result
}

const OrderVisualizationComponent = ({ orderId, leg }) => {
  const styles = useStyles();
  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [userToken, setUserToken] = useState();
  const [invoice_1, setInvoice_1] = useState(["N", "A"]);
  const [waybill_1, setWaybill_1] = useState(["N", "A"]);
  const [importdoc_1, setImportdoc_1] = useState(["N", "A"]);
  const [importdoc_2, setImportdoc_2] = useState(["N", "A"]);
  const [exportdoc_2, setExportdoc_2] = useState(["N", "A"]);
  const [invoice_2, setInvoice_2] = useState(["N", "A"]);
  const [waybill_2, setWaybill_2] = useState(["N", "A"]);
  const [proof_of_exit_2, setProof_of_exit_2] = useState(["N", "A"]);
  const [proof_of_delivery_2, setProof_of_delivery_2] = useState(["N", "A"]);
  const [shipments_1, setShipments_1] = useState(["-"])
  const [shipments_2, setShipments_2] = useState(["-"])
  const [shipment_status_1, setShipment_status_1] = useState(["-"])
  const [shipment_status_2, setShipment_status_2] = useState(["-"])
  const [typ_1, setTyp_1] = useState("-")
  const [typ_2, setTyp_2] = useState("-")
  const [node_1, setNode_1] = useState("-")
  const [node_2, setNode_2] = useState("-")
  const [node_3, setNode_3] = useState("-")
  
  let importdoc = importdoc_1;
 
  if (importdoc_2[0] ==="N" && importdoc_2[1] === "A") {
    importdoc = importdoc_1;
  } else {
    importdoc = importdoc_2;
  }

  const invoice_1_status = invoice_1[0] === invoice_1[1];
  const waybill_1_status = waybill_1[0] === waybill_1[1];
  const importdoc_status = importdoc[0] === importdoc[1];
  const exportdoc_2_status = exportdoc_2[0] === exportdoc_2[1];
  const invoice_2_status = invoice_2[0] === invoice_2[1];
  const waybill_2_status = waybill_2[0] === waybill_2[1];
  const proof_of_exit_2_status = proof_of_exit_2[0] === proof_of_exit_2[1];
  const proof_of_delivery_2_status = proof_of_delivery_2[0] === proof_of_delivery_2[1];
  const shipment_status_arrow_1 = shipment_status_1.reduce((acc, val) => acc && val === "OK", true);
  const shipment_status_arrow_2 = shipment_status_2.reduce((acc, val) => acc && val === "OK", true);
  const computed_space_1 = shipment_status_1.reduce((acc, val) => acc + 17, -17);
  const computed_space_2 = shipment_status_2.reduce((acc, val) => acc + 17, -17);
  let computed_space =  computed_space_1 > computed_space_2 ? computed_space_1 : computed_space_2 ;
  computed_space = computed_space < 0 ? 0 : computed_space;

  let legFormat = ""
  if (node_3 === "<Not defined>") {
    console.log('VISUAL FOR LEG 1');
    legFormat = "leg1"
  } else if (node_1 === "<Not defined>") {
    console.log('VISUAL FOR LEG 2');
    legFormat = "leg2"
  } else {
    console.log("VISUAL FOR LEG 1 AND LEG 2");
    legFormat = "leg1leg2"
  }

  const fetchVisualisationData = async () => {
    try {
      const token = user.user.signInUserSession.accessToken.jwtToken;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        Authorization: `Bearer ${token}`,
      };
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/order/${orderId}/visualization`, requestOptions);
      const dataRequested = await response.json();
      console.log('data: ', dataRequested);
      setInvoice_1(dataRequested[0]["1"]["Invoice"] || ["N", "A"])
      setWaybill_1(dataRequested[0]["1"]["Waybill"] || ["N", "A"])
      setImportdoc_1(dataRequested[0]["1"]["Import Document"] || ["N", "A"])
      setImportdoc_2(dataRequested[0]["2"]["Import Document"] || ["N", "A"])
      setExportdoc_2(dataRequested[0]["2"]["Export Document"] || ["N", "A"])
      setInvoice_2(dataRequested[0]["2"]["Invoice"] || ["N", "A"])
      setWaybill_2(dataRequested[0]["2"]["Waybill"] || ["N", "A"])
      setProof_of_exit_2(dataRequested[0]["2"]["Proof of Exit"] || ["N", "A"])
      setProof_of_delivery_2(dataRequested[0]["2"]["Proof of Delivery"] || ["N", "A"])
      setShipments_1(dataRequested[0]["1"]["Shipments"] || "-")
      setShipments_2(dataRequested[0]["2"]["Shipments"] || "-")
      setShipment_status_1(dataRequested[0]["1"]["Shipment Status"] || "-")
      setShipment_status_2(dataRequested[0]["2"]["Shipment Status"] || "-")
      setTyp_1(dataRequested[0]["1"]["typ"] || "-")
      setTyp_2(dataRequested[0]["2"]["typ"] || "-")
      setNode_1(dataRequested[0]["node1"] || "-")
      setNode_2(dataRequested[0]["node2"] || "-")
      setNode_3(dataRequested[0]["node3"] || "-")
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(()=>{
    fetchVisualisationData();
  },[userToken])

  useEffect(() => {
    if (!user.isLoading) {
      setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    }
  }, [user.isLoading]);


  return (
    <>
      <div className={styles.subHeaderContainer}>
        {legFormat === "leg1" ? (
          <div>
          <span></span>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_1}></Country></div>{node_1}</center></div></Grid>
            <Grid item xs={10.6}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_2}></Country></div>{node_2}</center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div id="origin_leg1"><center><FactoryOutlinedIcon style={iconStyle(shipment_status_arrow_1)} /></center></div></Grid>
            <Grid item xs={10.6}><div>
              <Xarrow 
              start="origin_leg1" 
              end="transit_leg1" 
              labels={<div className={styles.writtenElem} style={arrowBackgroundStyle(shipment_status_arrow_1)}><p style={{textAlign: "center", whiteSpace: "pre-line"}}>{mergeShipments(shipments_1, typ_1)}</p></div>}
              lineColor={arrowStyle(shipment_status_arrow_1)} 
              headColor={arrowStyle(shipment_status_arrow_1)} /></div></Grid>
            <Grid item xs={0.5}><div id="transit_leg1"><center><WarehouseOutlinedIcon style={iconStyle(shipment_status_arrow_1)}/></center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={11.3}><div style={{background: "", height: computed_space}}></div></Grid>
            <Grid item xs={0.5}><div id="importMarker" style={{background: "", height: "1px"}}></div></Grid>
            <Grid item xs={0.2}><div style={{height: "1px"}}></div></Grid>
          </Grid>
          <div style={{background: "", height: "40"}}></div>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={5.25}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(invoice_1_status)}/><p style={{whiteSpace: "pre-line"}}>Invoice{"\n"}{invoice_1[0]}/{invoice_1[1]}</p></center></div></Grid>
            <Grid item xs={0.5}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(waybill_1_status)}/><p style={{whiteSpace: "pre-line"}}>Waybill{"\n"}{waybill_1[0]}/{waybill_1[1]}</p></center></div></Grid>
            <Grid item xs={5.25}><div style={{background: "", height: "40px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={11.3}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div id="importDoc_leg1" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(importdoc_status)}/><p style={{whiteSpace: "pre-line"}}>Import{"\n"}{importdoc[0]}/{importdoc[1]}</p></center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "", height: "40px"}}></div></Grid>
          </Grid>
          <div>
              <Xarrow 
              start="transit_leg1" 
              end="importDoc_leg1" 
              lineColor= {arrowStyle(importdoc_status)}
              dashness={true}
              showHead={false}/></div>
          <span></span>
        </div>  
        ) : legFormat === "leg2" ? (
        <div>
          <span></span>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_2}></Country></div>{node_2}</center></div></Grid>
            <Grid item xs={10.6}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_3}></Country></div>{node_3}</center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div id="origin_leg2"><center><WarehouseOutlinedIcon style={iconStyle(shipment_status_arrow_2)}/></center></div></Grid>
            <Grid item xs={10.6}><div>
              <Xarrow 
              start="origin_leg2" 
              end="customer_leg2" 
              labels={<div className={styles.writtenElem} style={arrowBackgroundStyle(shipment_status_arrow_2)}><p style={{textAlign: "center", whiteSpace: "pre-line"}}>{mergeShipments(shipments_2, typ_2)}</p></div>} 
              lineColor={arrowStyle(shipment_status_arrow_2)}
              headColor={arrowStyle(shipment_status_arrow_2)}/></div></Grid>
            <Grid item xs={0.5}><div id="customer_leg2"><center><StorefrontOutlinedIcon style={iconStyle(shipment_status_arrow_2)}/></center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={0.2}><div style={{background: "", height: computed_space}}></div></Grid>
            <Grid item xs={0.5}><div id="exportMarker" style={{background: "", height: "1px"}}></div></Grid>
            <Grid item xs={11.3}><div style={{height: "1px"}}></div></Grid>
          </Grid>
          <div style={{background: "", height: "40"}}></div>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={5.25}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(invoice_2_status)}/><p style={{whiteSpace: "pre-line"}}>Invoice{"\n"}{invoice_2[0]}/{invoice_2[1]}</p></center></div></Grid>
            <Grid item xs={0.5}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(waybill_2_status)}/><p style={{whiteSpace: "pre-line"}}>Waybill{"\n"}{waybill_2[0]}/{waybill_2[1]}</p></center></div></Grid>
            <Grid item xs={5.25}><div style={{background: "", height: "40px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={0.2}><div style={{background: "", height: "40px"}}></div></Grid>
            {exportdoc_2[0] === "N" && exportdoc_2[1] === "A" ? (<Grid item xs={0.5}><div></div></Grid>) : (
            <Grid item xs={0.5}><div id="exportDoc_leg2" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(exportdoc_2_status)}/><p style={{whiteSpace: "pre-line"}}>Export{"\n"}{exportdoc_2[0]}/{exportdoc_2[1]}</p></center></div></Grid>)}
            <Grid item xs={9.6}><div style={{background: "", height: "40px"}}></div></Grid>      
            {proof_of_exit_2[0] === "N" && proof_of_exit_2[1] === "A" ? (<Grid item xs={0.5}><div></div></Grid>) : (
            <Grid item xs={0.5}><div id="poe_leg2" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(proof_of_exit_2_status)}/><p style={{whiteSpace: "pre-line"}}>Proof of Exit{"\n"}{proof_of_exit_2[0]}/{proof_of_exit_2[1]}</p></center></div></Grid>)}      
            <Grid item xs={0.5}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div id="pod_leg2" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(proof_of_delivery_2_status)}/><p style={{whiteSpace: "pre-line"}}>Proof of Delivery{"\n"}{proof_of_delivery_2[0]}/{proof_of_delivery_2[1]}</p></center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
            {exportdoc_2[0] === "N" && exportdoc_2[1] === "A" ? (<div></div>) : (
              <div>
              <Xarrow 
              start="origin_leg2" 
              end="exportDoc_leg2" 
              path="grid"
              lineColor={arrowStyle(exportdoc_2_status)}
              dashness={true}
              showHead={false}/></div>)}
            {proof_of_exit_2[0] === "N" && proof_of_exit_2[1] === "A" ? (<div></div>) : (
              <div>
              <Xarrow 
              start="customer_leg2" 
              end="poe_leg2" 
              path="grid"
              lineColor={arrowStyle(proof_of_exit_2_status)}
              dashness={true}
              showHead={false}/></div>)}
            <div>
              <Xarrow 
              start="customer_leg2" 
              end="pod_leg2" 
              lineColor={arrowStyle(proof_of_delivery_2_status)}
              dashness={true}
              showHead={false}/></div>
          <span></span>
        </div>  
        ) : (
        <div>
          <span></span>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={0.3}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.3}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_1}></Country></div>{node_1}</center></div></Grid>
            <Grid item xs={5.25}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.3}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_2}></Country></div>{node_2}</center></div></Grid>
            <Grid item xs={5.25}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.3}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_3}></Country></div>{node_3}</center></div></Grid>
            <Grid item xs={0.3}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div id="origin"><center><FactoryOutlinedIcon style={iconStyle(shipment_status_arrow_1)} /></center></div></Grid>
            <Grid item xs={4.54}><div>
              <Xarrow 
              start="origin" 
              end="transit" 
              labels={<div className={styles.writtenElem} style={arrowBackgroundStyle(shipment_status_arrow_1)}><p style={{textAlign: "center", whiteSpace: "pre-line"}}>{mergeShipments(shipments_1, typ_1)}</p></div>}
              lineColor={arrowStyle(shipment_status_arrow_1)} 
              headColor={arrowStyle(shipment_status_arrow_1)} /></div></Grid>
            <Grid item xs={0.5}><div id="importMarker2" style={{height: "50px"}}></div></Grid>
            <Grid item xs={0.5}><div id="transit"><center><WarehouseOutlinedIcon style={iconStyle(shipment_status_arrow_1)}/></center></div></Grid>
            <Grid item xs={0.5}><div id="exportMarker2" style={{height: "50px"}}></div></Grid>
            <Grid item xs={4.55}><div>
              <Xarrow 
              start="transit" 
              end="customer" 
              labels={<div className={styles.writtenElem} style={arrowBackgroundStyle(shipment_status_arrow_2)}><p style={{textAlign: "center", whiteSpace: "pre-line"}}>{mergeShipments(shipments_2, typ_2)}</p></div>} 
              lineColor={arrowStyle(shipment_status_arrow_2)}
              headColor={arrowStyle(shipment_status_arrow_2)}/></div></Grid>
            <Grid item xs={0.5}><div id="customer"><center><StorefrontOutlinedIcon style={iconStyle(shipment_status_arrow_2)}/></center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={5.25}><div style={{background: "", height: computed_space}}></div></Grid>
            <Grid item xs={0.5}><div id="importMarker" style={{background: "", height: "1px"}}></div></Grid>
            <Grid item xs={0.5}><div style={{background: "", height: "1px"}}></div></Grid>
            <Grid item xs={0.5}><div id="exportMarker" style={{background: "", height: "1px"}}></div></Grid>
            <Grid item xs={5.25}><div style={{height: "1px"}}></div></Grid>
          </Grid>
          <div style={{background: "", height: "40"}}></div>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={2.45}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(invoice_1_status)}/><p style={{whiteSpace: "pre-line"}}>Invoice{"\n"}{invoice_1[0]}/{invoice_1[1]}</p></center></div></Grid>
            <Grid item xs={0.5}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(waybill_1_status)}/><p style={{whiteSpace: "pre-line"}}>Waybill{"\n"}{waybill_1[0]}/{waybill_1[1]}</p></center></div></Grid>
            <Grid item xs={4.1}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(invoice_2_status)}/><p style={{whiteSpace: "pre-line"}}>Invoice{"\n"}{invoice_2[0]}/{invoice_2[1]}</p></center></div></Grid>
            <Grid item xs={0.5}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(waybill_2_status)}/><p style={{whiteSpace: "pre-line"}}>Waybill{"\n"}{waybill_2[0]}/{waybill_2[1]}</p></center></div></Grid>
            <Grid item xs={2.45}><div style={{background: "", height: "40px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={5.25}><div style={{background: "", height: "40px"}}></div></Grid>
            {importdoc[0] === "N" && importdoc[1] === "A" ? (<Grid item xs={0.5}><div></div></Grid>) : (
            <Grid item xs={0.5}><div id="importDoc" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(importdoc_status)}/><p style={{whiteSpace: "pre-line"}}>Import{"\n"}{importdoc[0]}/{importdoc[1]}</p></center></div></Grid>)}
            <Grid item xs={0.5}><div style={{background: "", height: "40px"}}></div></Grid>
            {exportdoc_2[0] === "N" && exportdoc_2[1] === "A" ? (<Grid item xs={0.5}><div></div></Grid>) : (
            <Grid item xs={0.5}><div id="exportDoc" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(exportdoc_2_status)}/><p style={{whiteSpace: "pre-line"}}>Export{"\n"}{exportdoc_2[0]}/{exportdoc_2[1]}</p></center></div></Grid>)}
            <Grid item xs={3.85}><div style={{background: "", height: "40px"}}></div></Grid>            
            {proof_of_exit_2[0] === "N" && proof_of_exit_2[1] === "A" ? (<Grid item xs={0.5}><div></div></Grid>) : (
            <Grid item xs={0.5}><div id="proofOfExit" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(proof_of_exit_2_status)}/><p style={{whiteSpace: "pre-line"}}>Proof of Exit{"\n"}{proof_of_exit_2[0]}/{proof_of_exit_2[1]}</p></center></div></Grid>)}
            <Grid item xs={0.2}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div id="proofOfDelivery" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={iconStyle(proof_of_delivery_2_status)}/><p style={{whiteSpace: "pre-line"}}>Proof of Delivery{"\n"}{proof_of_delivery_2[0]}/{proof_of_delivery_2[1]}</p></center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          {importdoc[0] === "N" && importdoc[1] === "A" ? (<div></div>) : (
            <div>
            <Xarrow 
            start="importMarker2" 
            end="importDoc" 
            path="grid"
            lineColor={arrowStyle(importdoc_status)}
            dashness={true}
            showHead={false}/></div>)}
          {exportdoc_2[0] === "N" && exportdoc_2[1] === "A" ? (<div></div>) : (
            <div>
            <Xarrow 
            start="exportMarker2" 
            end="exportDoc" 
            path="grid"
            lineColor={arrowStyle(exportdoc_2_status)}
            dashness={true}
            showHead={false}/></div>)}
          {proof_of_exit_2[0] === "N" && proof_of_exit_2[1] === "A" ? (<div></div>) : (
            <div>
            <Xarrow 
            start="customer" 
            end="proofOfExit" 
            path="grid"
            lineColor={arrowStyle(proof_of_exit_2_status)}
            dashness={true}
            showHead={false}/></div>)}
          <div>
            <Xarrow 
            start="customer" 
            end="proofOfDelivery" 
            lineColor={arrowStyle(proof_of_delivery_2_status)}
            dashness={true}
            showHead={false}/></div>
          <span></span>
        </div>  
        )}
      </div>
    </>
  );
};

export default OrderVisualizationComponent;
