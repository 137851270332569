import React, { useCallback, useMemo, useRef, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { DocumentsProcessStatus } from "../Components/CustomizeChip";
import UserContext from "../UserContext";

const useStyles = createUseStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 15px",
  },

  pageTitle: {
    padding: "10px 0px 5px 3px",
  },

  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 0.22,
  },

  headerButton: {
    padding: "0px 20px 0px 20px",
  },
  headerButtonLast: {
    paddingRight: "20px",
    paddingLeft: "10px",
  },

  maxDetailContainer: {
    height: "88vh !important",
    // width:"100vw !important"
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },

  titleIcon: {},
  descriptionContainer: {
    // flexDirection: "column",
    paddingLeft: "5px",
  },
  pageDescription: {
    color: "#B3B2B3",
  },
});

const OpenItemsDocuments = (props) => {
  const styles = useStyles();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [style, setStyle] = useState({
    height: "100%",
    width: "100%",
  });
  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });
  const gridRef = useRef();

  const getFilterData = async (tableName, columnName) =>{
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );
    const body = {tableName: tableName, column: columnName}
    
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow"
    };
    let dataToFilter = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/filter`,
      requestOptions
    )
      
    let dataToFilterJson = await dataToFilter.json()
    console.log('data :',dataToFilterJson)
    return dataToFilterJson
  }

  const [columnDefs, setColumnDefs] = useState([
    { field: "id", headerName: "ID",
      // Comparator for client side sorting
      /*comparator: (valueA, valueB)=>{
        const valueAint = parseInt(valueA)
        const valueBint = parseInt(valueB)
        if(valueAint === valueBint) return 0;
        else if(valueAint > valueBint) return 1;
        return -1;
      },*/
      filter: 'agNumberColumnFilter'
    },
    { field: "filename", headerName: "Filename", filter: 'agTextColumnFilter' },
    { field: "document_type", headerName: "Document Type", filter: 'agTextColumnFilter' },
    { field: "document_template", hide: true, filter: 'agTextColumnFilter' },
    {
      field: "process_status",
      headerName: "Status",
      cellRenderer: DocumentsProcessStatus,
      filter: 'agSetColumnFilter',
      filterParams: {
          values: async params => {
                let jsonData = await getFilterData('documents', 'process_status');
                console.log('final data',jsonData)
                params.success(jsonData)
            }
      }
    },
    { field: "channel_type", headerName: "Channel Type", hide: true , filter: 'agTextColumnFilter'},
    { field: "channel", filter: 'agTextColumnFilter' },
    {
      field: "classification_status",
      headerName: "Classification Status",
      hide: true,
      filter: 'agTextColumnFilter'
    },
    {
      field: "classification_type",
      headerName: "Classification Type",
      hide: true,
      filter: 'agTextColumnFilter'
    },
    {
      field: "classification_rule",
      headerName: "Classification Rule",
      hide: true,
      filter: 'agTextColumnFilter'
    },
    { field: "extraction_status", headerName: "Extraction Status", hide: true, filter: 'agTextColumnFilter' },
    { field: "mapping_status", headerName: "Mapping Status", hide: true, filter: 'agTextColumnFilter' },
    { field: "mapping_type", headerName: "Mapping Type", hide: true, filter: 'agTextColumnFilter' },
    { field: "mapping_rule", headerName: "Mapping Rule", hide: true, filter: 'agTextColumnFilter' },
    { field: "document_date", headerName: "Date", filter: 'agDateColumnFilter' },
    // { field: "extension", hide: true },
    { field: "filesize", hide: true, filter: 'agNumberColumnFilter' },
    { field: "action", hide: true,filter: 'agTextColumnFilter' },
    // { field: "comment" },
    // { field: "s3_id" },
    // { field: "textract" },
    // { field: "email_id" },
    // { field: "object_type", headerName: "Object Type" },
    // { field: "object", headerName: "Object" },
    // { field: "leg" },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter:true,
      flex: 1,
      filterParams: {
        suppressAndOrCondition: true,
      }
    };
  }, []);

  //Client Side Rendering
  /*const onGridReady = (params) => {
    setGridApi(params.api);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/view/open`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(data);
      });
  };*/

  const serverSideRendering = {
    getRows(params){
      // called by the grid when more rows are required
      //getRows: params => {
        
        console.log('Test of serverside rendering :',JSON.stringify(params.request, null, 1))

        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
        );

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(params.request),
          redirect: "follow"
        };
        
        fetch(
          `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/ssr/document/view/open`,
          requestOptions
        )
          .then((resp) => resp.json())
          .then((resp)=> {
            console.log('last',resp) 
            params.successCallback(resp.rows, resp.lastRow)
          })
          .catch((error) => {
            console.log("error", error);
            params.fail();
          })
    }
  }

  const serverSideDatasource = serverSideRendering;

  const setWidthAndHeight = (width, height) => {
    setStyle({
      width,
      height,
    });
  };

  const deselect = useCallback((e) => {
    gridRef.current.api.deselectAll();
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    const id = selectedRows.length === 1 ? selectedRows[0].id : "";

    navigate(`/documents/${id}`);
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    };
  }, []);

  return (
    <>
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.titleContainer}>
            <div className={styles.titleIcon}>
              <ArticleOutlinedIcon
                fontSize="large"
                sx={{
                  color: "#83b81a",
                }}
              />
            </div>
            <h1 className={styles.pageTitle}>Open Documents</h1>
          </div>
          <div>
            <div className={styles.descriptionContainer}>
              <p className={styles.pageDescription}>
                All which require manual action to complete processing. Use
                filters to find a specific document.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#F8F8F8", color: "black" }}
            onClick={deselect}
          >
            Deselect
          </Button>
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <div className="ag-theme-alpine" style={{ height: 500 }}>
          <div className={styles.maxDetailContainer}>
            <AgGridReact
              ref={gridRef}
              onCellClicked={cellClickedListener}
              onRowDoubleClicked={onSelectionChanged}
              //rowData={rowData} client side rendering
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowSelection="multiple"
              //onGridReady={onGridReady} client side rendering
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={50}
              cacheBlockSize={50}
              sideBar={sideBar}
              enableSorting={true}
              multiSortKey='ctrl'
              rowModelType='serverSide' //Need for SERVER SIDE REDERING 
              serverSideStoreType='partial'
              serverSideDatasource={serverSideDatasource}
              serverSideSortOnServer={true}
              serverSideFilterOnServer={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenItemsDocuments;
