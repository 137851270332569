//imports
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { createUseStyles } from "react-jss";
import { Document as PDFDocument, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { AgGridReact } from "ag-grid-react";
import UserContext from "../UserContext";
import { useContext } from "react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RecordStatus } from "../Components/CustomizeChip";
import { MmsRounded } from "@mui/icons-material";

//styles
const useStyles = createUseStyles({
  headerDocumentStatus: {
    height: "80px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
    boxShadow: "3px 3px 6px 2px #E2E0E2",
    marginBottom: "50px",
  },
  pageTitle: {
    padding: "10px 0px 10px 10px",
    // borderBottom: " 3px solid #F8F8F8",
    // color: "#B3B2B3",
    fontSize: "32px",
    marginBottom: 0,
  },

  statusStep: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusStepText: {
    marginBottom: 0,
  },
  circleActive: {
    borderRadius: "50%",
    width: "34px",
    height: "34px",
    padding: "6px",
    background: "#83b81a",
    color: "#fff",
    textAlign: "center",
    marginRight: "10px",
  },
  circleNotActive: {
    borderRadius: "50%",
    width: "34px",
    height: "34px",
    padding: "6px",
    background: "#E2E0E2",
    color: "#5E6A71",
    textAlign: "center",
    marginRight: "10px",
  },

  headerStatusSeparator: {
    width: "800px",
    height: "2px",
    background: "#E2E0E2",
  },

  optionalText: {
    color: "#B3B2B3",
  },

  pageDivider: {
    display: "flex",
    justifyContent: "space-between",
  },

  documentInfoContainer: {
    paddingLeft: "40px",
    // minWidth: "48%",

    flex: 2,
  },
  documentName: {
    fontSize: "34px",
  },

  documentInfo: {
    fontSize: "18px",
    color: "#B3B2B3",
  },

  documentInfoItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  separator: {
    height: "30px",
    width: "2px",
    background: "#E2E0E2",
  },

  tableValue: {
    fontSize: "18px",
  },

  tableRow: {
    paddingBottom: "10px",
  },

  table: {
    borderCollapse: "separate",
    borderSpacing: "0 1em",
  },

  formContainer: {
    paddingBottom: "10px",
  },

  formContainerSelect: {
    maxWidth: "450px",
    paddingTop: "10px",
  },

  formInfoLabel: {
    marginBottom: "10px",
    fontSize: "18px",
    color: "#B3B2B3",
  },

  buttonsGroup: {
    display: "flex",
    marginTop: "20px",
  },

  button: {
    padding: "0px 10px 0px 0px",
  },
  pdfContainer: {
    marginRight: "50px",
    maxWidth: "800px",
    // minHeight: "1000px",
    textAlign: "center",
    flex: 2,
  },

  invalidProcessingLoaderContainer: {
    // position: "absolute",
    display: "flex",
    top: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
  requiredAttributeInfo: {
    // display: "flex",
    // justifyContent: "space-between",
  },

  requiredAttributeField: {
    fontSize: "18px",
    color: "#B3B2B3",
    // flex: "2",
    minWidth: "350px",
    marginTop: "20px",
    marginBottom: "10px",
  },

  requiredAtrributeValue: {
    fontSize: "18px",

    "& label.Mui-focused": {
      color: "#83b81a",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#83b81a",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: "#83b81a",
      },
      "&:hover fieldset": {
        borderColor: "#83b81a",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#83b81a",
      },
    },
  },

  progressBarStyle: {
    backgroundColor: "#83b81a",
  },

  maxDetailContainer: {
    height: "88vh !important",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },

  titleIcon: {},
  descriptionContainer: {
    // flexDirection: "column",
    paddingLeft: "5px",
  },
  pageDescription: {
    color: "#B3B2B3",
  },
  subHeaderContainer: {
    paddingLeft: "35px",
  },
  statusPosition:{
    position:"relative",
    paddingLeft: "15px",
    top: "5px",
    paddingRight: "5px"
  },
});

const Invoice = () => {
  //HOOKS
  const styles = useStyles();
  const navigate = useNavigate();

  let { invoiceId } = useParams();

  const user = useContext(UserContext);
  const isLoading = useContext(UserContext);
  const [userToken, setUserToken] = useState();
  const [data, setData] = useState({});
  const [pdfData, setPDFData] = useState("");
  const [invoiceOrdersData, setInvoiceOrdersData] = useState({});
  const [loading, setLoading] = useState(true);
  // const [pdfLoading, setpdfLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const defaultColDefs = useMemo(
    () => [
      { field: "order_number", headerName: "Order Number", flex: 1 },
      { field: "subline_id", flex: 1, headerName: "Subline Number" },
    ],
    []
  );

  const gridRef = useRef();

  const cellClickedListener = useCallback((e) => {
    console.log("cellClicked", e);
  });

  // never changes, so we can use useMemo
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  var requestOptions = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  async function fetchInvoiceData() {
    const invoiceDataRequest = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/invoice/${invoiceId}`,
      requestOptions
    );

    setData(invoiceDataRequest.data);

    const invoiceOrdersDataRequest = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/invoice/${invoiceId}/orders`,
      requestOptions
    );

    setInvoiceOrdersData(invoiceOrdersDataRequest.data);
    console.log(invoiceOrdersData);

    const documentId = invoiceDataRequest.data[0].document_id;

    const pdfDataRequest = await axios
      .get(
        `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/document/${documentId}/file`,
        requestOptions
      )
      .then(setLoading(false))
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });

    console.log(pdfDataRequest.data);
    setPDFData(pdfDataRequest.data);
  }

  
 
  // const fetchPDFdata = () => {
  //   fetch(
  //     `https://tm8vcxydzi.execute-api.eu-central-1.amazonaws.com/dev/document/${data[0].document_id}/file`,
  //     {
  //       method: "GET",
  //       headers: { ticket: "96267EE23097fdfd31EEZ341657107125fsffs" },
  //     }
  //   ).then((data) => {
  //     setPDFData(data);
  //     setpdfLoading(false);
  //   });
  // };

  useEffect(() => {
    if (!user.isLoading) {
      setUserToken(user.user.signInUserSession.accessToken.jwtToken);
    }
  }, [user.isLoading]);
  useEffect(() => {
    if (userToken) {
      fetchInvoiceData();
    }
  }, [userToken]);

  //selectedRows are empty
  
  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    console.log("selectedRows", selectedRows)
    const id = selectedRows.length === 1 ? selectedRows[0].order_id : "";
    navigate(`/item/order/${id}`);
  }, []);


  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  if (loading) {
    return (
      <div className="invoices">
        <CircularProgress style={{ color: "gray" }} size={70} />
      </div>
    );
  }

  console.log(data);


  // if (pdfLoading) {
  //   <div className="invoices">
  //     <CircularProgress style={{ color: "gray" }} size={70} />
  //   </div>;
  // }
  // if (pdfData == null || pdfData.length() == 0) {
  //   console.log("This string is null or empty");
  // } else {
  //   console.log("This string is neither null nor empty");
  // }

  // console.log(pdfData);


  return (
    <>
      {/* <p className={styles.pageTitle}>Invoice: {data[0].invoice_number} </p> */}
      <div>
        <div className={styles.subHeaderContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.titleIcon}>
              <PaidOutlinedIcon
                fontSize="large"
                sx={{
                  color: "#83b81a",
                }}
              />
            </div>
            <h1 className={styles.pageTitle}>
              Invoice: {data[0].invoice_number}
            </h1>
            <p className={styles.statusPosition}><RecordStatus value={data[0].status}/></p>
          </div>
          <div>
            <div className={styles.descriptionContainer}>
              <p className={styles.pageDescription}>
                Orders included in this invoice
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pageDivider}>
        <div className={styles.documentInfoContainer}>
          <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
              <div className={styles.maxDetailContainer}>
                <AgGridReact
                  ref={gridRef}
                  onCellClicked={cellClickedListener}
                  onRowDoubleClicked={onSelectionChanged}
                  className="ag-theme-alpine"
                  animateRows="true"
                  columnDefs={defaultColDefs}
                  defaultColDef={defaultColDef}
                  enableRangeSelection="true"
                  rowData={invoiceOrdersData}
                  rowSelection="multiple"
                  //suppressRowClickSelection="true"
                  suppressColumnVirtualisation="true"
                  pagination={true}
                  paginationPageSize={50}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.pdfContainer}>
          <PDFDocument file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={800} height={850} />
          </PDFDocument>
        </div>
      </div>
    </>
  );
};

export default Invoice;
