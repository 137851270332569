import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import UserContext from "../UserContext";
import { CircularProgress } from "@mui/material";



const useStyles = createUseStyles({});

const Home = () => {
  const styles = useStyles();
  const user = useContext(UserContext);
  const [embededUrl, setEmbededUrl] = useState();
  const [loading, setIsLoading] = useState(true);

  //generate embeded url
  const getQuicksightEmbededUrl = async () => {
    if (!user || !user.user || !user.user.signInUserSession) {
        console.log("User session not available");
        return;
    }
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`);
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/quicksight/embed`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
        }
        const responseData = await response.json();
        const embedUrl = responseData.EmbedUrl;
        setEmbededUrl(embedUrl);
        setIsLoading(false);
        console.log("EmbedUrl:", embedUrl);
    } catch (error) {
        console.log("error", error);
    }
};



useEffect(() => { 
  if (user && user.user && user.user.signInUserSession) {
    getQuicksightEmbededUrl(); 
  } 
}, [user]);


  return (
    <div>
      {loading || !embededUrl ? (

        <div className="invoices">
          <CircularProgress style={{ color: "gray" }} size={150} />  
        </div>

      ) : (
        <iframe
          style={{ height: "100vh", width: "-webkit-fill-available" }}
          width="960"
          height="720"
          src={embededUrl}
        ></iframe>
      )}
    </div>
  );
};

export default Home;