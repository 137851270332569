import React, { useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { createUseStyles } from 'react-jss';
import { useContext } from "react";
import UserContext from "../UserContext";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';

const useStyles = createUseStyles({
    maxDetailContainer: {
      height: "88vh !important",
    },
    buttons:{
        float:'right',
        paddingRight:'5px'
    }
  });

const AvailabilityRulesDetailRenderer = ({ data, node, api }) => {
    const styles = useStyles();
    const rowId = node.id;
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const containerStyle = useMemo(() => ({ display:'flex', width: "100%", height: "100%" }), []);
    const user = useContext(UserContext);
    const gridRef = useRef();
    

    useEffect(() => {
        return () => {
        console.log('removing detail grid info with id: ', rowId);

        // the detail grid is automatically destroyed as it is a React component
        api.removeDetailGridInfo(rowId);
        };
    }, []);

    const colDefs = [
        { field: "id", headerName: "ID", hide: true , editable: false},
        { field: "object" },
        { field: "field" },
        { field: "operator" },
        { field: "condition" },
        { field: "active" },
        { field: "idRuleSet",headerName:'Rule ID', hide: true, editable:false}
    ];

    const defaultColDef = {
        flex: 1,
        editable: true
    };

    const onGridReady = (params) => {
        const gridInfo = {
        id: node.id,
        api: params.api,
        columnApi: params.columnApi,
        };

        console.log('adding detail grid info with id: ', rowId);
        console.log('data', data.rules)
        console.log('gridInfo', gridInfo)
        api.addDetailGridInfo(rowId, gridInfo);
    };

    const updateAvailabilityDetails = (newValue) => {
        const body = {type: 'availabilityDetails' , data: newValue.data}
        console.log(newValue)
        console.log(body)
        
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
        );
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body:JSON.stringify(body),
        };
    
        fetch(
          `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/rules/availability`,
          requestOptions
        )
          //.then((resp) => console.log(resp))
          .then((resp) => resp.json())
          .catch((error)=>{
            console.log("error", error);
          })
          
    }

    const onAddRow = () => {
        gridRef.current.api.applyTransaction({ add: [{'idRuleSet' : data.id}]})
    }

    const onRemoveSelectedRow = () =>{
        var selectedRowData = gridRef.current.api.getSelectedRows()
        selectedRowData.forEach(element => {
            let rowToRemove = {'data':{'id':element.id}}
            console.log(rowToRemove)
            updateAvailabilityDetails(rowToRemove)
        });
        gridRef.current.api.applyTransaction({remove: selectedRowData}) // TODO : IF ANY ERROR OCCURED NOT APPLY IT
    }

    return (
        <div>
            <div className={styles.buttons}>
                        <IconButton onClick={onAddRow} sx={{color: '#83b81a'}} aria-label="Add new availability rule"> 
                            <AddCircleOutlineIcon/>
                        </IconButton>
                        <IconButton onClick={onRemoveSelectedRow} sx={{color: '#83b81a'}} aria-label="Remove selected availability rule"> 
                            <RemoveCircleOutlineIcon/>
                        </IconButton>
                        </div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                        <div className={styles.maxDetailContainer}>
                        
                        <AgGridReact
                            ref={gridRef}
                            rowSelection={'multiple'}
                            columnDefs={colDefs}
                            defaultColDef={defaultColDef}
                            rowData={data.rules}
                            onGridReady={onGridReady}
                            editType={'fullRow'}
                            onRowValueChanged={updateAvailabilityDetails}
                            enableCellChangeFlash={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AvailabilityRulesDetailRenderer;