import React from "react";
import IconButton from "@mui/material/IconButton";
import { Auth } from "aws-amplify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserContext from "../UserContext";
import { useContext } from "react";

export const DotMenu = ({}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useContext(UserContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // console.log(user);
  };

  async function handleSignOut(event) {
    event.preventDefault(); // Lets the user stay on the page and not get redirected to the signout's URL.
    try {
      await Auth.signOut(); // Signs the user out.
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error); // If something went wrong, log it.
    }
    // @ts-ignore
    // setUser(null); // Update the 'user' state variable to reflect this case
    // setIsLoading(false); // Update the 'isLoading' state variable to reflect this case
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon
          style={{
            color:
              window.location.pathname === "/dashboard" ? "#83b81a" : "#83b81a",
            fontSize: "24",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleSignOut}>Logout</MenuItem>
        {/* <MenuItem>Logout</MenuItem> */}
      </Menu>
    </div>
  );
};
